<template>
    <span>{{ num }}</span>
</template>

<script>
export default {
    data() {
        return {
            num: 0,
        }
    },

    props: {
        // 数值
        number: {
            type: Number | String,
            default: 0,
        },
        // 加载时间，默认 1000ms
        time: {
            type: Number | String,
            default: 1000,
        },
    },

    watch: {
        number: {
            handler(v) {
                if (v != 0 || this.num != 0) {
                    this.run()
                }
            },
            immediate: true,
        },
    },

    methods: {
        run() {
            let n = this.number
            let s = Math.round(Math.abs((n - this.num) * 0.005)) || 1
            let t = this.time / (n / s)

            if (n > this.num) {
                this.increase(n, t, s)
            } else {
                this.reduce(n, t, s)
            }
        },

        /**
         * 增加
         */
        increase(n, t, s) {
            this.interval = setInterval(() => {
                if (this.num + s >= n) {
                    this.num = n
                    // 清除定时器
                    clearInterval(this.interval)
                } else {
                    this.num += s
                }
            }, t)
        },

        /**
         * 减少
         */
        reduce(n, t, s) {
            this.interval = setInterval(() => {
                if (this.num - s <= n) {
                    this.num = n
                    // 清除定时器
                    clearInterval(this.interval)
                } else {
                    this.num -= s
                }
            }, t || 1)
        },
    },
}
</script>

<style></style>
